import React, {Component} from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import CustomNavbar from './component/CustomNavbar';
import Banner from './component/Banner/Banner';
import About from './component/About';
import Features from './component/Features';
import Faq from './component/Faq/Faq';
import Mission from './component/Mission/Mission';
import Team from './component/Team';
import Action from './component/Action';
import Staking from './component/Staking';
import Footer from './component/Footer/Footer';
import Partners from './component/Partners';
import Blog from './component/Blog/Blog';
import $ from 'jquery';
import WOW from 'wowjs';
import {apiAddress, stakingProviderIdentity} from "./config";
import {getProvider} from "./apiRequests/providers";
import {NumberUtils} from "./common/utils/NumberUtils";
import {getEgldValue} from "./apiRequests/egldPrice";
import Testimonial from "./component/Testimonial/Testimonial";
import {getMediumPosts} from './apiRequests/medium';

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nodesNumber: 0,
            delegatorsNumber: 0,
            egldDelegated: 0,
            egldUsdcValue: 0,
            cumulatedRewards: 0,
            currentApr: 0,
            serviceFee: 0,
            posts: {}
        };
    }

    componentDidMount() {
        if (typeof window !== 'undefined') {
            const wow = new WOW.WOW({
                live: false,
            })
            wow.init()
        }
        var head = $(".navbar");
        var stick = "shrink";

        $(window).scroll(function () {
            $(window).scrollTop() > 0
                ? head.addClass(stick)
                : head.removeClass(stick)
        })

        $('.navbar li a').on('click', function (event) {
            var $anchor = $(this);
            $('html, body').stop().animate({
                scrollTop: $($anchor.attr('href')).offset().top - 75
            }, 1500);
            event.preventDefault();
        });
        

        getProvider({
            apiAddress,
            identity: stakingProviderIdentity,
            timeout: 5000,
        }).then(({data}) => {
            if (!data) {
                return undefined;
            }

            this.setState({
                nodesNumber: data.numNodes,
                cumulatedRewards: Number(NumberUtils.toDenominatedString(data.cumulatedRewards)),
                delegatorsNumber: Number(data.numUsers),
                currentApr: data.apr,
                serviceFee: data.serviceFee,
                egldDelegated: Number(NumberUtils.toDenominatedString(data.stake)) + Number(NumberUtils.toDenominatedString(data.topUp))
            });
        });

        getEgldValue({
            apiAddress: 'https://tools.multiversx.com',
            timeout: 5000,
        }).then(({data}) => {
            if (!data) {
                return undefined;
            }

            this.setState({
                egldUsdcValue: Number(data.currentPrice)
            });
        });

        getMediumPosts({
            timeout: 5000,
        }).then(({data}) => {
            if (!data) {
                return undefined;
            }
            this.setState({
                posts: data.items.slice(0, 4)
            });
        });
    }

    render() {
        return (
            <Router>
                <div className="App">
                    <div className="body_wrapper">
                        <CustomNavbar/>
                        <Banner
                            egldUsdcValue={this.state.egldUsdcValue}
                            currentApr={this.state.currentApr}/>
                        <About/>
                        <Staking
                            nodesNumber={this.state.nodesNumber}
                            delegatorsNumber={this.state.delegatorsNumber}
                            egldDelegated={this.state.egldDelegated}
                            serviceFee={this.state.serviceFee}
                            currentApr={this.state.currentApr}
                            egldUsdcValue={this.state.egldUsdcValue}
                        />
                        <Team/>
                        <Mission/>
                        <Features/>
                        <Action/>
                        <Faq/>
                        <Partners/>
                        <Testimonial />
                        <Blog posts={this.state.posts}/>
                        <Footer/>
                    </div>
                </div>
            </Router>
        )
    }
}

export default App;
